import { GameCard } from '@/components/games/game-card';
import { useAuth } from '@/lib/auth';
import { Game, GameCompletion, UserGame, supabase } from '@/lib/supabase';
import { Loader2, X } from 'lucide-react';
import { useEffect, useState } from 'react';

export function GamesPage() {
  const { user } = useAuth();
  const [games, setGames] = useState<Game[]>([]);
  const [userGames, setUserGames] = useState<UserGame[]>([]);
  const [completions, setCompletions] = useState<GameCompletion[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    async function loadGames() {
      try {
        console.log('Fetching games from Supabase...');
        const { data: gamesData, error: gamesError } = await supabase
          .from('games')
          .select('*');
        
        if (gamesError) {
          console.error('Supabase error:', gamesError);
          throw gamesError;
        }

        if (user) {
          const today = new Date().toISOString().split('T')[0];

          // Fetch user's games
          const { data: userGamesData, error: userGamesError } = await supabase
            .from('user_games')
            .select('*')
            .eq('user_id', user.id);

          if (userGamesError) {
            console.error('Error fetching user games:', userGamesError);
            throw userGamesError;
          }

          // Fetch today's completions
          const { data: completionsData, error: completionsError } = await supabase
            .from('game_completions')
            .select('*')
            .eq('user_id', user.id)
            .gte('completed_at', today);

          if (completionsError) {
            console.error('Error fetching completions:', completionsError);
            throw completionsError;
          }

          setUserGames(userGamesData || []);
          setCompletions(completionsData || []);
        }
        
        console.log('Games fetched:', gamesData);
        setGames(gamesData || []);
      } catch (err) {
        console.error('Error loading games:', err);
        setError(err instanceof Error ? err.message : 'Failed to load games');
      } finally {
        setLoading(false);
      }
    }

    loadGames();
  }, [user]);

  const toggleGameInList = async (gameId: string) => {
    if (!user) return;

    const isInList = userGames.some(ug => ug.game_id === gameId);

    try {
      if (isInList) {
        await supabase
          .from('user_games')
          .delete()
          .eq('user_id', user.id)
          .eq('game_id', gameId);

        setUserGames(userGames.filter(ug => ug.game_id !== gameId));
      } else {
        const { data, error } = await supabase
          .from('user_games')
          .insert([{ user_id: user.id, game_id: gameId }])
          .select();

        if (error) throw error;
        if (data) setUserGames([...userGames, ...data]);
      }
    } catch (err) {
      console.error('Error toggling game:', err);
    }
  };

  const toggleCompletion = async (gameId: string) => {
    if (!user) return;

    const today = new Date().toISOString().split('T')[0];
    const isCompleted = completions.some(
      c => c.game_id === gameId && new Date(c.completed_at).toISOString().split('T')[0] === today
    );

    try {
      if (isCompleted) {
        await supabase
          .from('game_completions')
          .delete()
          .eq('user_id', user.id)
          .eq('game_id', gameId)
          .gte('completed_at', today);

        setCompletions(completions.filter(
          c => !(c.game_id === gameId && new Date(c.completed_at).toISOString().split('T')[0] === today)
        ));
      } else {
        const { data, error } = await supabase
          .from('game_completions')
          .insert([{
            user_id: user.id,
            game_id: gameId,
            completed_at: new Date().toISOString()
          }])
          .select();

        if (error) throw error;
        if (data) setCompletions([...completions, ...data]);
      }
    } catch (err) {
      console.error('Error toggling completion:', err);
    }
  };

  const toggleTag = (tag: string) => {
    setSelectedTags(prev => 
      prev.includes(tag) 
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  const clearTags = () => {
    setSelectedTags([]);
  };

  const getAllTags = () => {
    const tagSet = new Set<string>();
    games.forEach(game => {
      game.tags.forEach(tag => tagSet.add(tag));
    });
    return Array.from(tagSet).sort();
  };

  const filteredGames = games.filter(game =>
    selectedTags.length === 0 || selectedTags.every(tag => game.tags.includes(tag))
  );

  const userGamesList = filteredGames.filter(game => 
    userGames.some(ug => ug.game_id === game.id)
  );

  if (loading) {
    return (
      <div className="flex min-h-[calc(100vh-4rem)] items-center justify-center">
        <div className="text-center">
          <Loader2 className="mx-auto h-8 w-8 animate-spin text-primary" />
          <div className="mt-4 text-lg text-foreground">Loading games...</div>
          <div className="mt-2 text-sm text-muted-foreground">
            Please wait while we fetch the latest games
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex min-h-[calc(100vh-4rem)] items-center justify-center">
        <div className="mx-auto max-w-md text-center">
          <div className="rounded-lg bg-destructive/10 p-6">
            <div className="text-lg font-semibold text-destructive">Failed to load games</div>
            <div className="mt-2 text-sm text-destructive/80">{error}</div>
            <div className="mt-4 text-sm text-muted-foreground">
              Please make sure you've connected to Supabase and try refreshing the page
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (games.length === 0) {
    return (
      <div className="flex min-h-[calc(100vh-4rem)] items-center justify-center">
        <div className="mx-auto max-w-md text-center">
          <div className="rounded-lg bg-card/50 backdrop-blur-sm p-6 border">
            <div className="text-lg font-semibold text-foreground">No games found</div>
            <div className="mt-2 text-sm text-muted-foreground">
              Make sure you've connected to Supabase and the database is properly initialized
            </div>
          </div>
        </div>
      </div>
    );
  }

  const today = new Date().toISOString().split('T')[0];
  const isGameCompletedToday = (gameId: string) => 
    completions.some(
      c => c.game_id === gameId && new Date(c.completed_at).toISOString().split('T')[0] === today
    );

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {user && userGamesList.length > 0 && (
          <div className="mb-16">
            <h2 className="text-2xl font-bold tracking-tight bg-gradient-to-br from-slate-900 to-slate-600 dark:from-white dark:to-slate-300 bg-clip-text text-transparent">
              Your Games
            </h2>
            <p className="mt-2 text-lg text-muted-foreground">
              Quick access to your favorite daily challenges
            </p>
            <div className="mt-6 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {userGamesList.map((game) => (
                <GameCard 
                  key={game.id} 
                  game={game}
                  isInUserList={true}
                  onToggleInList={() => toggleGameInList(game.id)}
                  completedToday={isGameCompletedToday(game.id)}
                  onToggleCompletion={() => toggleCompletion(game.id)}
                />
              ))}
            </div>
          </div>
        )}

        <div className="space-y-4">
          <h1 className="text-4xl font-bold tracking-tight bg-gradient-to-br from-slate-900 to-slate-600 dark:from-white dark:to-slate-300 bg-clip-text text-transparent">
            All Games
          </h1>
          <p className="max-w-2xl text-lg text-muted-foreground">
            Discover and track your favorite daily puzzle games, all in one place. 
            New challenges await you every day.
          </p>
        </div>

        <div className="mt-8">
          <div className="flex flex-wrap items-center gap-2">
            {getAllTags().map(tag => (
              <button
                key={tag}
                onClick={() => toggleTag(tag)}
                className={`
                  inline-flex items-center rounded-full px-3 py-1 text-sm font-medium transition-colors
                  ${selectedTags.includes(tag)
                    ? 'bg-primary text-primary-foreground hover:bg-primary/90'
                    : 'bg-accent/50 backdrop-blur-sm text-accent-foreground hover:bg-accent/70'
                  }
                `}
              >
                {tag}
              </button>
            ))}
            {selectedTags.length > 0 && (
              <button
                onClick={clearTags}
                className="inline-flex items-center rounded-full bg-destructive/10 px-3 py-1 text-sm font-medium text-destructive hover:bg-destructive/20"
              >
                <X className="mr-1 h-4 w-4" />
                Clear filters
              </button>
            )}
          </div>
        </div>

        <div className="mt-8 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {filteredGames.map((game) => (
            <GameCard 
              key={game.id} 
              game={game}
              isInUserList={userGames.some(ug => ug.game_id === game.id)}
              onToggleInList={user ? () => toggleGameInList(game.id) : undefined}
              completedToday={isGameCompletedToday(game.id)}
              onToggleCompletion={user ? () => toggleCompletion(game.id) : undefined}
            />
          ))}
        </div>
      </div>
    </div>
  );
}