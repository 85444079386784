import { Button } from '@/components/ui/button';
import { useAuth } from '@/lib/auth';
import { Game, GameCompletion, GameStats, UserGame, UserSettings, supabase } from '@/lib/supabase';
import { Calendar, Loader2, Settings, Trophy } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';

function calculateStats(completions: GameCompletion[]): GameStats {
  const dates = completions.map(c => new Date(c.completed_at).toISOString().split('T')[0]);
  const uniqueDates = [...new Set(dates)].sort();

  let currentStreak = 0;
  let longestStreak = 0;
  let streak = 0;

  // Calculate streaks
  const today = new Date().toISOString().split('T')[0];
  for (let i = 0; i < uniqueDates.length; i++) {
    const date = uniqueDates[i];
    const prevDate = i > 0 ? uniqueDates[i - 1] : null;
    
    if (!prevDate || isConsecutiveDay(prevDate, date)) {
      streak++;
    } else {
      streak = 1;
    }

    longestStreak = Math.max(longestStreak, streak);
    
    if (isConsecutiveDay(date, today)) {
      currentStreak = streak;
    }
  }

  return {
    total_completions: completions.length,
    current_streak: currentStreak,
    longest_streak: longestStreak,
    completion_dates: uniqueDates
  };
}

function isConsecutiveDay(date1: string, date2: string): boolean {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const diffTime = Math.abs(d2.getTime() - d1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays === 1;
}

function formatDate(date: string): string {
  return new Date(date).toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });
}

export function ProfilePage() {
  const { username } = useParams<{ username: string }>();
  const { user } = useAuth();
  const [profileUser, setProfileUser] = useState<UserSettings | null>(null);
  const [userGames, setUserGames] = useState<Game[]>([]);
  const [completions, setCompletions] = useState<GameCompletion[]>([]);
  const [stats, setStats] = useState<GameStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadUserData() {
      if (!username) return;

      try {
        // Get user settings for the profile
        const { data: profileData, error: profileError } = await supabase
          .from('user_settings')
          .select('*')
          .eq('username', username)
          .single();

        if (profileError) throw profileError;
        if (!profileData) throw new Error('Profile not found');
        
        // Check if profile is public or belongs to current user
        if (!profileData.is_public && (!user || user.id !== profileData.user_id)) {
          throw new Error('This profile is private');
        }

        setProfileUser(profileData);

        // Get user's game relationships
        const { data: userGamesData, error: userGamesError } = await supabase
          .from('user_games')
          .select('game_id')
          .eq('user_id', profileData.user_id);

        if (userGamesError) throw userGamesError;

        // Get game completions
        const { data: completionsData, error: completionsError } = await supabase
          .from('game_completions')
          .select('*')
          .eq('user_id', profileData.user_id)
          .order('completed_at', { ascending: false });

        if (completionsError) throw completionsError;

        setCompletions(completionsData || []);

        if (userGamesData && userGamesData.length > 0) {
          // Get the actual game details
          const gameIds = userGamesData.map((ug: UserGame) => ug.game_id);
          const { data: games, error: gamesError } = await supabase
            .from('games')
            .select('*')
            .in('id', gameIds);

          if (gamesError) throw gamesError;
          setUserGames(games || []);
        } else {
          setUserGames([]);
        }

        // Calculate stats
        if (completionsData) {
          setStats(calculateStats(completionsData));
        }
      } catch (err) {
        console.error('Error loading profile data:', err);
        setError(err instanceof Error ? err.message : 'Failed to load profile data');
      } finally {
        setLoading(false);
      }
    }

    loadUserData();
  }, [username, user]);

  if (loading) {
    return (
      <div className="flex min-h-[calc(100vh-4rem)] items-center justify-center">
        <div className="text-center">
          <Loader2 className="mx-auto h-8 w-8 animate-spin text-primary" />
          <div className="mt-4 text-lg text-foreground">Loading profile...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex min-h-[calc(100vh-4rem)] items-center justify-center">
        <div className="mx-auto max-w-md text-center">
          <div className="rounded-lg bg-destructive/10 p-6">
            <div className="text-lg font-semibold text-destructive">{error}</div>
            <div className="mt-4">
              <Button asChild variant="outline" className="bg-background/50 backdrop-blur-sm">
                <Link to="/">Return Home</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!profileUser) {
    return <Navigate to="/" replace />;
  }

  const getGameById = (id: string): Game | undefined => {
    return userGames.find(game => game.id === id);
  };

  const isOwnProfile = user?.id === profileUser.user_id;

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-card/50 backdrop-blur-sm shadow-lg border">
          <div className="px-4 py-5 sm:p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:items-center">
                <div className="flex h-16 w-16 items-center justify-center rounded-full bg-accent">
                  <span className="text-2xl font-medium text-accent-foreground">
                    {profileUser.username[0].toUpperCase()}
                  </span>
                </div>
                <div className="mt-4 sm:ml-4 sm:mt-0">
                  <p className="text-xl font-semibold bg-gradient-to-br from-slate-900 to-slate-600 dark:from-white dark:to-slate-300 bg-clip-text text-transparent">@{profileUser.username}</p>
                  <p className="text-sm text-muted-foreground">Member since {new Date(profileUser.created_at).toLocaleDateString()}</p>
                </div>
              </div>
              {isOwnProfile && (
                <div className="mt-5 sm:ml-4 sm:mt-0">
                  <Button variant="outline" asChild className="flex items-center bg-background/50 backdrop-blur-sm">
                    <Link to="/settings">
                      <Settings className="mr-2 h-4 w-4" />
                      Edit Profile
                    </Link>
                  </Button>
                </div>
              )}
            </div>

            <div className="mt-8 border-t border-border pt-8">
              <div className="grid gap-4 sm:grid-cols-3">
                <div className="rounded-lg bg-card/50 backdrop-blur-sm p-4 border">
                  <div className="flex items-center">
                    <Trophy className="h-5 w-5 text-primary" />
                    <h3 className="ml-2 text-sm font-medium text-foreground">Games Tracked</h3>
                  </div>
                  <p className="mt-2 text-2xl font-semibold text-foreground">{userGames.length}</p>
                </div>
                {stats && (
                  <>
                    <div className="rounded-lg bg-card/50 backdrop-blur-sm p-4 border">
                      <div className="flex items-center">
                        <Calendar className="h-5 w-5 text-primary" />
                        <h3 className="ml-2 text-sm font-medium text-foreground">Current Streak</h3>
                      </div>
                      <p className="mt-2 text-2xl font-semibold text-foreground">
                        {stats.current_streak} days
                      </p>
                    </div>
                    <div className="rounded-lg bg-card/50 backdrop-blur-sm p-4 border">
                      <div className="flex items-center">
                        <Trophy className="h-5 w-5 text-primary" />
                        <h3 className="ml-2 text-sm font-medium text-foreground">Longest Streak</h3>
                      </div>
                      <p className="mt-2 text-2xl font-semibold text-foreground">
                        {stats.longest_streak} days
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="mt-8 border-t border-border pt-8">
              <h3 className="text-lg font-medium bg-gradient-to-br from-slate-900 to-slate-600 dark:from-white dark:to-slate-300 bg-clip-text text-transparent">Tracked Games</h3>
              {userGames.length === 0 ? (
                <div className="mt-4 text-center">
                  <p className="text-sm text-muted-foreground">No games being tracked yet.</p>
                </div>
              ) : (
                <div className="mt-4">
                  <div className="overflow-hidden rounded-lg border bg-card/50 backdrop-blur-sm">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="bg-muted/50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-muted-foreground">
                            Game
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-muted-foreground">
                            Category
                          </th>
                          <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-muted-foreground">
                            Completions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border">
                        {userGames.map((game) => {
                          const gameCompletions = completions.filter(c => c.game_id === game.id);
                          return (
                            <tr key={game.id}>
                              <td className="whitespace-nowrap px-6 py-4">
                                <div className="flex items-center">
                                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-accent text-accent-foreground">
                                    <span className="text-lg font-medium">
                                      {game.name[0].toUpperCase()}
                                    </span>
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-sm font-medium text-foreground">{game.name}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4">
                                <div className="flex gap-1">
                                  {game.tags.map((tag) => (
                                    <span
                                      key={tag}
                                      className="inline-flex items-center rounded-full bg-accent/50 backdrop-blur-sm px-2.5 py-0.5 text-xs font-medium text-accent-foreground"
                                    >
                                      {tag}
                                    </span>
                                  ))}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-muted-foreground">
                                {gameCompletions.length} times
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-8 border-t border-border pt-8">
              <h3 className="text-lg font-medium bg-gradient-to-br from-slate-900 to-slate-600 dark:from-white dark:to-slate-300 bg-clip-text text-transparent">Recent Activity</h3>
              {completions.length === 0 ? (
                <div className="mt-4 text-center">
                  <p className="text-sm text-muted-foreground">No activity yet.</p>
                </div>
              ) : (
                <div className="mt-4 flow-root">
                  <ul className="-mb-8">
                    {completions.slice(0, 10).map((completion, index) => {
                      const game = getGameById(completion.game_id);
                      if (!game) return null;

                      return (
                        <li key={completion.id}>
                          <div className="relative pb-8">
                            {index !== completions.length - 1 && (
                              <span
                                className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-border"
                                aria-hidden="true"
                              />
                            )}
                            <div className="relative flex items-start space-x-3">
                              <div className="relative">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-accent text-accent-foreground ring-8 ring-card">
                                  <span className="text-lg font-medium">
                                    {game.name[0].toUpperCase()}
                                  </span>
                                </div>
                              </div>
                              <div className="min-w-0 flex-1">
                                <div>
                                  <div className="text-sm">
                                    <a href={game.url} className="font-medium text-foreground hover:text-primary">
                                      {game.name}
                                    </a>
                                  </div>
                                  <p className="mt-0.5 text-sm text-muted-foreground">
                                    Completed on {formatDate(completion.completed_at)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}