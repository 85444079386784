import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export function AboutPage() {
  return (
    <div className="min-h-[calc(100vh-4rem)] py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h1 className="text-4xl font-bold tracking-tight sm:text-5xl bg-gradient-to-br from-slate-900 to-slate-600 dark:from-white dark:to-slate-300 bg-clip-text text-transparent">
            About Each Daily
          </h1>
          <p className="mt-6 text-lg leading-8 text-muted-foreground">
            Your daily companion for tracking and discovering the web's best daily puzzle games.
            One place, every day, every game.
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl space-y-16">
          <div className="space-y-6">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
              Our Mission
            </h2>
            <p className="text-muted-foreground">
              Each Daily was born from a simple observation: the internet is filled with amazing
              daily puzzle games, but keeping track of them all is a challenge. We believe in the
              power of daily mental exercises and want to make it easier for everyone to discover,
              enjoy, and track their progress across all their favorite daily challenges.
            </p>
          </div>

          <div className="space-y-6">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
              Features
            </h2>
            <ul className="space-y-4 text-muted-foreground">
              <li className="flex items-start">
                <span className="mr-3 text-lg">🎮</span>
                <span>Comprehensive collection of daily puzzle games in one place</span>
              </li>
              <li className="flex items-start">
                <span className="mr-3 text-lg">🎯</span>
                <span>Track your completion streaks across multiple games</span>
              </li>
              <li className="flex items-start">
                <span className="mr-3 text-lg">🌟</span>
                <span>Discover new daily challenges based on your interests</span>
              </li>
              <li className="flex items-start">
                <span className="mr-3 text-lg">🤝</span>
                <span>Join a community of daily puzzle enthusiasts</span>
              </li>
            </ul>
          </div>

          <div className="space-y-6">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
              Get Started
            </h2>
            <p className="text-muted-foreground">
              Ready to streamline your daily puzzle routine? Create an account to start tracking
              your progress and discover new daily challenges.
            </p>
            <div className="flex justify-center">
              <Button
                asChild
                size="lg"
                className="bg-gradient-to-r from-primary to-blue-600 hover:from-primary/90 hover:to-blue-600/90"
              >
                <Link to="/signup">Sign up for free</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}