import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export type Game = {
  id: string;
  name: string;
  description: string;
  url: string;
  image_url?: string;
  tags: string[];
  created_at: string;
  updated_at: string;
};

export type UserGame = {
  user_id: string;
  game_id: string;
  created_at: string;
};

export type GameCompletion = {
  id: string;
  user_id: string;
  game_id: string;
  completed_at: string;
  created_at: string;
};

export type GameStats = {
  total_completions: number;
  current_streak: number;
  longest_streak: number;
  completion_dates: string[];
};

export type UserSettings = {
  user_id: string;
  username: string;
  is_public: boolean;
  created_at: string;
  updated_at: string;
};