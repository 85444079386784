import { Button } from '@/components/ui/button';
import { useAuth } from '@/lib/auth';
import { UserSettings, supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

export function SettingsPage() {
  const { user } = useAuth();
  const [settings, setSettings] = useState<UserSettings | null>(null);
  const [username, setUsername] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    async function loadSettings() {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('user_settings')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;

        setSettings(data);
        setUsername(data.username);
        setIsPublic(data.is_public);
      } catch (err) {
        console.error('Error loading settings:', err);
        setError(err instanceof Error ? err.message : 'Failed to load settings');
      } finally {
        setLoading(false);
      }
    }

    loadSettings();
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !settings) return;

    setSaving(true);
    setError(null);
    setSuccessMessage(null);

    try {
      // Check if username is taken (if changed)
      if (username !== settings.username) {
        const { data: existingUser } = await supabase
          .from('user_settings')
          .select('username')
          .eq('username', username)
          .neq('user_id', user.id)
          .single();

        if (existingUser) {
          throw new Error('Username is already taken');
        }
      }

      const { error } = await supabase
        .from('user_settings')
        .update({
          username,
          is_public: isPublic,
        })
        .eq('user_id', user.id);

      if (error) throw error;

      setSettings({
        ...settings,
        username,
        is_public: isPublic,
      });
      setSuccessMessage('Settings updated successfully');
    } catch (err) {
      console.error('Error saving settings:', err);
      setError(err instanceof Error ? err.message : 'Failed to save settings');
    } finally {
      setSaving(false);
    }
  };

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return (
      <div className="flex min-h-[calc(100vh-4rem)] items-center justify-center">
        <div className="text-center">
          <Loader2 className="mx-auto h-8 w-8 animate-spin text-primary" />
          <div className="mt-4 text-lg text-foreground">Loading settings...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-[calc(100vh-4rem)] bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 py-12">
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-card/50 backdrop-blur-sm shadow-lg border">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-2xl font-bold bg-gradient-to-br from-slate-900 to-slate-600 dark:from-white dark:to-slate-300 bg-clip-text text-transparent">
              Account Settings
            </h1>
            <p className="mt-1 text-sm text-muted-foreground">
              Manage your account preferences and profile visibility
            </p>

            <form onSubmit={handleSubmit} className="mt-6 space-y-6">
              {error && (
                <div className="rounded-md bg-destructive/10 p-4">
                  <div className="text-sm text-destructive">{error}</div>
                </div>
              )}

              {successMessage && (
                <div className="rounded-md bg-green-100 dark:bg-green-900/30 p-4">
                  <div className="text-sm text-green-600 dark:text-green-400">{successMessage}</div>
                </div>
              )}

              <div>
                <label htmlFor="username" className="block text-sm font-medium text-foreground">
                  Username
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="block w-full rounded-md border bg-background/50 border-input px-3 py-2 text-foreground shadow-sm placeholder:text-muted-foreground focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                    required
                    pattern="^[a-zA-Z0-9_-]{3,20}$"
                    title="Username must be between 3 and 20 characters and can only contain letters, numbers, underscores, and hyphens"
                  />
                </div>
                <p className="mt-2 text-sm text-muted-foreground">
                  This is your public username. It must be unique and will be used in your profile URL.
                </p>
              </div>

              <div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="is_public"
                    id="is_public"
                    checked={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                    className="h-4 w-4 rounded border-input bg-background text-primary focus:ring-primary"
                  />
                  <label htmlFor="is_public" className="ml-2 block text-sm text-foreground">
                    Make my profile public
                  </label>
                </div>
                <p className="mt-2 text-sm text-muted-foreground">
                  When enabled, your profile and game activity will be visible to everyone.
                </p>
              </div>

              <div className="flex items-center justify-end space-x-4">
                <Button
                  type="submit"
                  disabled={saving || username === settings?.username && isPublic === settings?.is_public}
                  className="bg-gradient-to-r from-primary to-blue-600 hover:from-primary/90 hover:to-blue-600/90"
                >
                  {saving ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}