import { Button } from '@/components/ui/button';
import { ThemeToggle } from '@/components/theme-toggle';
import { useAuth } from '@/lib/auth';
import { UserSettings, supabase } from '@/lib/supabase';
import { LogIn, LogOut, Menu, Settings, TowerControl } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const { user, signOut } = useAuth();

  useEffect(() => {
    async function loadUserSettings() {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('user_settings')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        setUserSettings(data);
      } catch (err) {
        console.error('Error loading user settings:', err);
      }
    }

    loadUserSettings();
  }, [user]);

  return (
    <nav className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm shadow-lg">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <Link to="/" className="flex items-center">
              <TowerControl className="h-8 w-8 text-primary" />
              <span className="ml-2 text-xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                Each Daily
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden sm:flex sm:items-center sm:space-x-4">
            <Button variant="ghost" size="sm" asChild>
              <Link to="/games" className="text-foreground hover:text-primary">Games</Link>
            </Button>
            <Button variant="ghost" size="sm" asChild>
              <Link to="/about" className="text-foreground hover:text-primary">About</Link>
            </Button>
            <ThemeToggle />
            {user ? (
              <>
                <Button variant="ghost" size="sm" asChild>
                  <Link to={`/profile/${userSettings?.username || ''}`} className="text-foreground hover:text-primary">
                    Profile
                  </Link>
                </Button>
                <Button variant="ghost" size="sm" asChild>
                  <Link to="/settings" className="text-foreground hover:text-primary">
                    <Settings className="mr-2 h-4 w-4" />
                    Settings
                  </Link>
                </Button>
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={signOut}
                  className="bg-background/50 backdrop-blur-sm text-foreground hover:text-primary"
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  Sign Out
                </Button>
              </>
            ) : (
              <Button 
                variant="outline" 
                size="sm" 
                asChild
                className="bg-background/50 backdrop-blur-sm text-foreground hover:text-primary"
              >
                <Link to="/login">
                  <LogIn className="mr-2 h-4 w-4" />
                  Sign In
                </Link>
              </Button>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <ThemeToggle />
            <button
              type="button"
              className="ml-2 inline-flex items-center justify-center rounded-md p-2 text-foreground hover:bg-accent hover:text-accent-foreground"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              <Button variant="ghost" size="sm" asChild className="w-full justify-start">
                <Link to="/games" className="text-foreground hover:text-primary">Games</Link>
              </Button>
              <Button variant="ghost" size="sm" asChild className="w-full justify-start">
                <Link to="/about" className="text-foreground hover:text-primary">About</Link>
              </Button>
              {user ? (
                <>
                  <Button variant="ghost" size="sm" asChild className="w-full justify-start">
                    <Link to={`/profile/${userSettings?.username || ''}`} className="text-foreground hover:text-primary">
                      Profile
                    </Link>
                  </Button>
                  <Button variant="ghost" size="sm" asChild className="w-full justify-start">
                    <Link to="/settings" className="text-foreground hover:text-primary">Settings</Link>
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={signOut}
                    className="w-full justify-start text-foreground hover:text-primary"
                  >
                    Sign Out
                  </Button>
                </>
              ) : (
                <Button variant="ghost" size="sm" asChild className="w-full justify-start">
                  <Link to="/login" className="text-foreground hover:text-primary">Sign In</Link>
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}