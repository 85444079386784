import { Button } from '@/components/ui/button';
import { Game } from '@/lib/supabase';
import { Check, ExternalLink, Heart, Info } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface GameCardProps {
  game: Game;
  isInUserList?: boolean;
  onToggleInList?: () => void;
  completedToday?: boolean;
  onToggleCompletion?: () => void;
}

export function GameCard({ 
  game, 
  isInUserList, 
  onToggleInList,
  completedToday,
  onToggleCompletion 
}: GameCardProps) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="group relative overflow-hidden rounded-xl border bg-card/50 backdrop-blur-sm shadow-lg transition-all hover:shadow-xl">
      <div className="p-6">
        <div className="flex items-start justify-between">
          <h3 className="font-semibold text-foreground">
            <Link to={`/games/${game.id}`} className="hover:text-primary">
              {game.name}
            </Link>
          </h3>
          <div className="flex gap-2">
            <button
              onClick={() => setShowInfo(!showInfo)}
              className="rounded-full p-2 text-muted-foreground transition-colors hover:bg-accent hover:text-accent-foreground"
              aria-label="Toggle game information"
            >
              <Info size={20} />
            </button>
            {onToggleCompletion && (
              <button
                onClick={onToggleCompletion}
                className={`rounded-full p-2 transition-colors ${
                  completedToday
                    ? 'bg-green-100 text-green-600 dark:bg-green-900/30 dark:text-green-400 hover:bg-green-200 dark:hover:bg-green-900/50'
                    : 'text-muted-foreground hover:bg-accent hover:text-accent-foreground'
                }`}
                aria-label={completedToday ? 'Mark as incomplete' : 'Mark as complete'}
              >
                <Check size={20} />
              </button>
            )}
            {onToggleInList && (
              <button
                onClick={onToggleInList}
                className={`rounded-full p-2 transition-colors ${
                  isInUserList
                    ? 'bg-red-100 text-red-500 dark:bg-red-900/30 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-900/50'
                    : 'text-muted-foreground hover:bg-accent hover:text-accent-foreground'
                }`}
                aria-label={isInUserList ? 'Remove from my games' : 'Add to my games'}
              >
                <Heart className={isInUserList ? 'fill-current' : ''} size={20} />
              </button>
            )}
          </div>
        </div>

        <div className={`mt-2 overflow-hidden transition-all ${
          showInfo ? 'max-h-40' : 'max-h-12'
        }`}>
          <p className="text-sm text-muted-foreground">{game.description}</p>
        </div>

        <div className="mt-4 flex flex-wrap gap-2">
          {game.tags.map((tag) => (
            <span
              key={tag}
              className="inline-flex items-center rounded-full bg-accent/50 backdrop-blur-sm px-2.5 py-0.5 text-xs font-medium text-accent-foreground hover:bg-accent/70 cursor-pointer transition-colors"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.location.href = `/games?tag=${tag}`;
              }}
            >
              {tag}
            </span>
          ))}
        </div>

        <div className="mt-4">
          <Button asChild variant="outline" size="sm" className="w-full bg-background/50 backdrop-blur-sm">
            <a 
              href={game.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center"
            >
              Play Now
              <ExternalLink className="ml-2 h-4 w-4" />
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}