import { supabase } from '@/lib/supabase';
import { User } from '@supabase/supabase-js';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signUp: (email: string, password: string, username: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      const currentUser = session?.user ?? null;
      setUser(currentUser);

      // If we have a user but no settings, create them
      if (currentUser) {
        const { data: existingSettings } = await supabase
          .from('user_settings')
          .select('*')
          .eq('user_id', currentUser.id)
          .single();

        if (!existingSettings) {
          // Create default settings with a unique username based on email
          const baseUsername = currentUser.email?.split('@')[0] || 'user';
          let username = baseUsername;
          let counter = 1;

          // Keep trying until we find a unique username
          while (true) {
            const { data: existing } = await supabase
              .from('user_settings')
              .select('username')
              .eq('username', username)
              .single();

            if (!existing) break;
            username = `${baseUsername}${counter}`;
            counter++;
          }

          await supabase
            .from('user_settings')
            .insert([
              {
                user_id: currentUser.id,
                username,
                is_public: false,
              },
            ]);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const signUp = async (email: string, password: string, username: string) => {
    // First check if username is available
    const { data: existingUser } = await supabase
      .from('user_settings')
      .select('username')
      .eq('username', username)
      .single();

    if (existingUser) {
      throw new Error('Username is already taken');
    }

    const { error: signUpError, data } = await supabase.auth.signUp({
      email,
      password,
    });
    
    if (signUpError) throw signUpError;

    if (data.user) {
      const { error: settingsError } = await supabase
        .from('user_settings')
        .insert([
          {
            user_id: data.user.id,
            username,
            is_public: false,
          },
        ]);
      
      if (settingsError) throw settingsError;
    }

    navigate('/games');
  };

  const signIn = async (email: string, password: string) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) throw error;
    navigate('/games');
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signUp,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}