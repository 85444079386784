import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outline' | 'ghost';
  size?: 'default' | 'sm' | 'lg';
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', size = 'default', asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          'inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:pointer-events-none disabled:opacity-50',
          {
            'default': 'bg-slate-900 text-slate-50 shadow hover:bg-slate-900/90',
            'outline': 'border border-slate-200 bg-white hover:bg-slate-100 hover:text-slate-900',
            'ghost': 'hover:bg-slate-100 hover:text-slate-900',
          }[variant],
          {
            'default': 'h-9 px-4 py-2',
            'sm': 'h-8 rounded-md px-3 text-xs',
            'lg': 'h-10 rounded-md px-8',
          }[size],
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = 'Button';

export { Button };