import { Navbar } from '@/components/layout/navbar';
import { AuthProvider } from '@/lib/auth';
import { ThemeProvider } from '@/lib/theme-provider';
import { AboutPage } from '@/pages/about';
import { GamesPage } from '@/pages/games';
import { HomePage } from '@/pages/home';
import { LoginPage } from '@/pages/login';
import { ProfilePage } from '@/pages/profile';
import { SettingsPage } from '@/pages/settings';
import { SignUpPage } from '@/pages/signup';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        <AuthProvider>
          <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 transition-colors duration-300">
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/games" element={<GamesPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/profile/:username" element={<ProfilePage />} />
            </Routes>
          </div>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;